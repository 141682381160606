import moment from 'moment';

export const required = value =>
  (value === '' || value === null || value === undefined)
    ? 'This field is required.'
    : undefined

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address.'
    : undefined

export const positiveNumber = value =>
  value && value < 0
    ? 'Positive number only.'
    : undefined

export const numeric = value =>
  value && !/^[0-9]+$/i.test(value)
    ? 'Number only.'
    : undefined

export const alphanumeric = value =>
  value && !/^[a-zA-Z0-9.,_-\s]+$/i.test(value)
    ? 'Alphabet, number, _ - . , only.'
    : undefined

export const username = value =>
  value && !/^[a-zA-Z0-9.@+_-]+$/i.test(value)
    ? 'Letters, digits and @/./+/-/_ only.'
    : undefined

const minLength = (min, message) => value =>
  value && value.length < min
    ? message
    : undefined

const maxLength = (max, message) => value =>
  value && value.length > max
    ? message
    : undefined

export const minLength1 = minLength(1, 'Select at least 1 item.');
export const minLength7 = minLength(7, 'Provide as least 7 characters.');
export const maxLength8 = maxLength(8, '8 characters or fewer.');
export const maxLength30 = maxLength(30, '30 characters or fewer.');
export const maxLength50 = maxLength(50, '50 characters or fewer.');
export const maxLength100 = maxLength(100, '100 characters or fewer.');
export const maxLength128 = maxLength(128, '128 characters or fewer.');
export const maxLength150 = maxLength(150, '150 characters or fewer.');
export const maxLength246 = maxLength(246, '246 characters or fewer.');
export const maxLength254 = maxLength(254, '254 characters or fewer.');


const validateDeadline = (values, errors) => {
  if (moment(values.deadlineDate).isBefore(values.receiveDate)) {
    errors.deadlineDate = 'Deadline Date < Receive Date';
  }
  return errors
}

const validateAssignmentDeadline = (role, values, errors) => {
  if ((
    values.deadlineDate &&
    values.deadlineTime &&
    values[role] &&
    values[role].startDate &&
    values[role].startTime &&
    values[role].endDate &&
    values[role].endTime
  )) {

      const deadlineDateTime = moment(`${values.deadlineDate} ${values.deadlineTime}`);
      const startDateTime = moment(`${values[role].startDate} ${values[role].startTime}`);
      const endDateTime = moment(`${values[role].endDate} ${values[role].endTime}`);
      errors[role] = {};
      if (endDateTime.isBefore(startDateTime)) {
        errors[role].startDate = 'Start Date Time > End Date Time';
        errors[role].startTime = 'Start Date Time > End Date Time';
      } else if (deadlineDateTime.isBefore(startDateTime)) {
        errors[role].startDate = 'Deadline Date Time > Start Date Time';
        errors[role].startTime = 'Deadline Date Time > Start Date Time';
      }
      if (deadlineDateTime.isBefore(endDateTime)) {
        errors[role].endDate = 'Deadline Date Time > End Date Time';
        errors[role].endTime = 'Deadline Date Time > End Date Time';
      }
    }
  return errors;
}


export const jobFormValidate = (values = {}) => {
  let errors = {};
  errors = validateDeadline(values, errors);
  return errors
}

export const jobFormFullValidate = (values = {}) => {
  let errors = {};
  errors = validateDeadline(values, errors);
  errors = validateAssignmentDeadline('totra', values, errors);
  errors = validateAssignmentDeadline('translator', values, errors);
  errors = validateAssignmentDeadline('editor', values, errors);
  errors = validateAssignmentDeadline('checker', values, errors);
  errors = validateAssignmentDeadline('finalEditor', values, errors);
  errors = validateAssignmentDeadline('secondFinalEditor', values, errors);
  errors = validateAssignmentDeadline('dtp', values, errors);
  errors = validateAssignmentDeadline('secondDtp', values, errors);
  return errors
}


export const jobSearchFormValidate = (values = {}) => {
  let errors = {};
  if (moment(values.end).isBefore(values.start)) {
    errors.start = 'Start > End';
  }
  return errors
}