import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FormGroup, Form, ControlLabel, Checkbox} from 'react-bootstrap';
import { normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import { mhtSchema } from 'schemas';
import { sync } from 'actions/entities';
import { MEMORY_SUGGEST_RATIOS, SEARCH_METHOD_OPTIONS } from 'misc/config';
import Select from 'components/core/Select';
import ClientAsyncSelect from 'components/core/ClientAsyncSelect';
import JobNumberAsyncSelect from 'components/core/JobNumberAsyncSelect';
import {TaModal, TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import MHTApi from "services/MHTApi";
import isEqual from 'lodash.isequal';
import isempty from 'lodash.isempty';
import {TaButton} from "components/taUi/taButton/taButton";
import {TaFormGroup} from "components/taUi/taFormGroup/taFormGroup";
import PureRenderComponent from 'components/core/PureRenderComponent';


class TaMhtEditorSettingsMemory extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      isDirty: false,
      isSaving: false,
      formData: {
        reference_clients: [],
        reference_jobs: [],
        exclude_penalty: false,
        search_method: SEARCH_METHOD_OPTIONS[0].value,
        memory_suggest_ratio: MEMORY_SUGGEST_RATIOS[0].value,
      }
    };
  }

  componentDidMount() {
    if (!isempty(this.props.mht)) {
      this.mapPropsToFormDataState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.mht, this.props.mht)) {
      this.mapPropsToFormDataState();
    }
  }

  mapPropsToFormDataState = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        exclude_penalty: this.props.mht.excludePenalty,
        search_method: this.props.mht.searchMethod,
        memory_suggest_ratio: this.props.mht.memorySuggestRatio,
        reference_clients: this.props.mht.referenceClients,
        reference_jobs: this.props.mht.referenceJobs,
      }
    });
  }

  onCancel = () => {
    if (typeof this.props.onCancel === 'function') {
      this.props.onCancel();
    }
  };

  onSubmit = () => {
    const id = this.props.mht.id;

    this.setState({
      isSaving: true
    }, () => {
      MHTApi
        .update(id, this.state.formData)
        .then(response => {
          const normalized = normalize(camelizeKeys(response.data), mhtSchema);
          const { result, entities: { mht } } = normalized;
          this.props.sync('mht', result, mht[result]);
          if (typeof this.props.onConfirm === 'function') {
            this.props.onConfirm(response.data);
          }
        })
        .catch(error => {
          console.error('listSegmentTerms - error', error);
        })
        .finally(() => {
          this.setState({
            isSaving: false
          });
        });

    });

  };

  onInputChange = (key, value) => {
    this.setState({
      isDirty: true,
      formData: {
        ...this.state.formData,
        [key]: value
      }
    });
  };

  onInputChangeArrayChange = (key, value) => {
    const newValue = value ? value.split(',') : []
    this.setState({
      isDirty: true,
      formData: {
        ...this.state.formData,
        [key]: newValue
      }
    });
  }

  render() {
    return (
      <TaModal
        isOpen={this.props.isOpen}
        onCancel={this.onCancel}
      >
        <TaModalHeader
          label={'Specify Translation Memory'}
          onCancel={this.onCancel}
        />
        <TaModalContent scroll={'auto'}>
          <TaFormGroup>
            <Form>
              <FormGroup>
                <ControlLabel>Client</ControlLabel>
                <ClientAsyncSelect
                  value={this.state.formData.reference_clients}
                  multi={true}
                  onChange={value => this.onInputChangeArrayChange('reference_clients', value)}/>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Job</ControlLabel>
                <JobNumberAsyncSelect
                  value={this.state.formData.reference_jobs}
                  multi={true}
                  onChange={value => this.onInputChangeArrayChange('reference_jobs', value)}/>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Memory Suggest Ratio</ControlLabel>
                <Select
                  value={this.state.formData.memory_suggest_ratio}
                  onChange={value => this.onInputChange('memory_suggest_ratio', value)}
                  options={MEMORY_SUGGEST_RATIOS} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Search Method</ControlLabel>
                <Select
                  value={this.state.formData.search_method}
                  onChange={value => this.onInputChange('search_method', value)}
                  options={SEARCH_METHOD_OPTIONS} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Penalty</ControlLabel>
                <Checkbox
                  className="mt-0"
                  checked={this.state.formData.exclude_penalty}
                  onChange={e => this.onInputChange('exclude_penalty', e.target.checked)}>Exclude penalty
                  TM</Checkbox>
              </FormGroup>
            </Form>
          </TaFormGroup>
        </TaModalContent>
        <TaModalFooter>
          <TaButton
            type="reset"
            icon={'clear'}
            label={'Cancel'}
            onClick={this.onCancel}
          />
          <TaButton
            type="submit"
            icon={'check'}
            label={'Save'}
            iconAlign={'right'}
            onClick={this.onSubmit}
            // disabled={!state.isDirty}
            isLoading={this.state.isSaving}
          />
        </TaModalFooter>
      </TaModal>
    );
  }
}


const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    sync
  }, disptach);
};

export default connect(
  null,
  mapDispatchToProps
)(TaMhtEditorSettingsMemory);
