import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import DateFormatter from 'components/core/DateFormatter';
import classNames from 'classnames';
import TooltipIconButton from 'components/core/TooltipIconButton';
import FontAwesome from 'react-fontawesome';

const Empty = (props) => (
  <div
    style={{ backgroundColor: '#f9f9f9' }}
    className="text-center p-10">
    No Rows To Show
  </div>
);

const MHTJobMessage = (props) => {
  const {
    messages,
    isLoading,
    isMessageAllRead,
    showMessageAddModal,
    handleReadMessage,
    handleReadAllMessages,
  } = props;

  const renderMessage = (message) => {
    const fromCustomer = message.fromCustomer;
    const fromStaff = !fromCustomer;
    const offset = fromCustomer ? 0 : 6;
    const isOpen = message.read;

    const messageCls = classNames({
      "mht-message": true,
      "clearfix": true,
      'mht-message-customer': fromCustomer,
      'mht-message-staff': fromStaff
    })
    const readCls = classNames({
      "mht-message-read-icon": true,
      "mht-message-read-open": isOpen,
      "mht-message-read-staff": fromStaff
    })

    return (
      <Row key={message.id}>
        <Col md={6} mdOffset={offset}>
          <div className={messageCls}>
            <div className="mht-message-body">
              {message.body}
            </div>
            <div className="mht-message-footer">
              <span className="mht-message-datetime"><DateFormatter datetime={message.created} /></span>
              <span className="mht-message-read">
                <FontAwesome
                  className={readCls}
                  name="check"
                  disabled={message.read}
                  onClick={() => handleReadMessage(message.id, isOpen, fromStaff)} />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    )
  }

  return (
    <Box className="box-widget mb-10" loading={isLoading}>
      <Box.Header>
        <Box.Title>Messages</Box.Title>
        <Box.Tools>
          <TooltipIconButton
            id="add-new-message"
            className="btn-box-tool"
            icon="envelope-o"
            description="Add New Message"
            onClick={showMessageAddModal} />
          <TooltipIconButton
            id="read-message"
            className="btn-box-tool"
            icon="check"
            disabled={isMessageAllRead()}
            description="Read All Messages"
            onClick={handleReadAllMessages} />
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        {
          messages && messages.length > 0
            ? messages.map(message => renderMessage(message))
            : <Empty />
        }
      </Box.Body>
    </Box>
  )
}

export default MHTJobMessage;