import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import VirtualizedSelect from 'react-virtualized-select'
import { autocompleteRequest } from 'actions/jobs/managers';
import { getJobNumberOptions } from 'selectors/options';
import { isJobNumberAutoCompleteLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';


class JobNumberAsyncSelect extends PureRenderComponent {

  handleInputChange = (value) => {
    value && this.props.autocompleteRequest(value);
  }

  render() {

    const {
      value,
      onChange,
      clearable,
      disabled,
      className,
      isLoading,
      style,
      multi,
      placeholder,
      numberOptions
    } = this.props;

    return (
      <VirtualizedSelect
        simpleValue={true}
        clearable={clearable}
        disabled={disabled}
        isLoading={isLoading}
        value={value}
        onChange={onChange}
        options={numberOptions}
        className={className}
        multi={multi}
        placeholder={placeholder}
        style={style}
        onInputChange={(value) => this.handleInputChange(value)}
        filterOption={(option, filterString) => {
          return option.label && option.label.toLowerCase().indexOf(filterString) !== -1
        }} />
    )
  }
}


const mapStateToProps = (state) => {
  return {
    isLoading: isJobNumberAutoCompleteLoading(state),
    numberOptions: getJobNumberOptions(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    autocompleteRequest,
  }, disptach)
}

JobNumberAsyncSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(JobNumberAsyncSelect);


export default JobNumberAsyncSelect;
