import orderBy from 'lodash.orderby';
import groupBy from 'lodash.groupby';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import * as schemas from 'schemas';
import * as coreSelectors from 'selectors/core';
import * as constants from 'misc/constants';
import { PER_PAGE_ITEM, EMPLOYEE_STATUS_MAP } from 'misc/config';
import {getEntities, getMemorySearchEntities, getWordListEntities, getGlossaryEntities} from 'selectors/entities';
import {
  getJobScheduleCurrentDate, getEmployeeJobScheduleCurrentDate,
  getSalesReportCurrentMonth, getInvoiceListCurrentMonth,
  getSalesTargetCurrentYear
} from 'selectors/filters';
import { getCurrentTabParams } from "selectors/globalTabs";


const _getDenormalizedJobs = (jobIds, entities) => {
  return denormalize(jobIds, [schemas.jobSchema], entities)
}


// Daily Schedule ==============================================================
const _getDailySchedule = state => state.lists[constants.JOB_DAILY_SCHEDULE];
const getCurrentDateSchedule = createSelector(
  [getJobScheduleCurrentDate, _getDailySchedule],
  (currentDate, dailySchedule) => dailySchedule[currentDate]
)
export const getDailySchedule = createSelector(
  [getCurrentDateSchedule, getEntities],
  (currentDateSchedule, entities) => {
    const jobIds = currentDateSchedule ? currentDateSchedule : [];
    const dailySchedule = denormalize(jobIds, [schemas.jobSchema], entities);
    return {
      toEnJobs: dailySchedule.filter(job => job.service.dst === 'en-US'),
      toJpJobs: dailySchedule.filter(job => job.service.dst === 'ja-JP'),
      toOtherJobs: dailySchedule.filter(job => job.service.dst !== 'en-US' && job.service.dst !== 'ja-JP')
    }
  }
)

const _getFutureSchedule = state => state.lists[constants.JOB_FUTURE_SCHEDULE];
const getCurrentDateFutureSchedule = createSelector(
  [getJobScheduleCurrentDate, _getFutureSchedule],
  (currentDate, futureSchedule) => futureSchedule[currentDate]
)
export const getFutureSchedule = createSelector(
  [getCurrentDateFutureSchedule, getEntities],
  (currentDateSchedule, entities) => {
    const jobIds = currentDateSchedule ? currentDateSchedule : [];
    return denormalize(jobIds, [schemas.jobSchema], entities);
  }
)

// Employee Job Daily Schedule =====================================================
const _getEmployeeJobDailySchedule = state => state.lists[constants.EMPLOYEE_JOB_DAILY_SCHEDULE]
const getCurrentDateEmployeeJobDailySchedule = createSelector(
  [getEmployeeJobScheduleCurrentDate, _getEmployeeJobDailySchedule],
  (currentDate, dailySchedule) => dailySchedule[currentDate]
)
export const getEmployeeJobDailySchedule = createSelector(
  [getCurrentDateEmployeeJobDailySchedule, getEntities],
  (currentDateSchedule, entities) => {
    const jobIds = currentDateSchedule ? currentDateSchedule : [];
    return denormalize(jobIds, [schemas.jobSchema], entities);
  }
)

// Employee Job Future Schedule ================================================
const _getEmployeeJobFutureSchedule = state => state.lists[constants.EMPLOYEE_JOB_FUTURE_SCHEDULE];
const getCurrentDateEmployeeJobFutureSchedule = createSelector(
  [getEmployeeJobScheduleCurrentDate, _getEmployeeJobFutureSchedule],
  (currentDate, futureSchedule) => futureSchedule[currentDate]
)
export const getEmployeeJobFutureSchedule = createSelector(
  [getCurrentDateEmployeeJobFutureSchedule, getEntities],
  (currentDateSchedule, entities) => {
    const jobIds = currentDateSchedule ? currentDateSchedule : [];
    return denormalize(jobIds, [schemas.jobSchema], entities);
  }
)

// Job Search ==================================================================
const getJobSearchTotalCount = state => state.lists[constants.JOB_SEARCH].totalCount;
const getJobSearchPages = state => state.lists[constants.JOB_SEARCH].pages;
export const getJobSearchCurrentPage = state => state.lists[constants.JOB_SEARCH].currentPage;
export const getJobSearchPageSize = createSelector(
  [getJobSearchTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageSearchJobIds = createSelector(
  [getJobSearchCurrentPage, getJobSearchPages], (currentPage, pages) => pages[currentPage] || []
)
export const getSearchJobs = createSelector(
  [getCurrentPageSearchJobIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.jobSchema], entities)
)


// Pending Jobs ================================================================
const _getPendingJobs = state => state.lists[constants.PENDING_LIST];
export const getPendingJobs = createSelector(
  [_getPendingJobs, getEntities], _getDenormalizedJobs
);

// Preparing Jobs ================================================================
const _getPreparingJobs = state => state.lists[constants.PREPARING_LIST];
export const getPreparingJobs = createSelector(
  [_getPreparingJobs, getEntities], _getDenormalizedJobs
);

// ToTra Current Jobs ==========================================================
const _getToTraCurrentJobs = state => state.lists[constants.TOTRA_CURRENT_JOBS];
export const getToTraCurrentJobs = createSelector(
  [_getToTraCurrentJobs, getEntities], _getDenormalizedJobs
)

// Translator Current Jobs =====================================================
const _getTranslatorCurrentJobs = state => state.lists[constants.TRANSLATOR_CURRENT_JOBS];
export const getTranslatorCurrentJobs = createSelector(
  [_getTranslatorCurrentJobs, getEntities], _getDenormalizedJobs
)

// Editor Current Jobs =========================================================
const _getEditorCurrentJobs = state => state.lists[constants.EDITOR_CURRENT_JOBS];
export const getEditorCurrentJobs = createSelector(
  [_getEditorCurrentJobs, getEntities], _getDenormalizedJobs
)

// Checker Current Jobs ========================================================
const _getCheckerCurrentJobs = state => state.lists[constants.CHECKER_CURRENT_JOBS];
export const getCheckerCurrentJobs = createSelector(
  [_getCheckerCurrentJobs, getEntities], _getDenormalizedJobs
)

// Final Editor Current Jobs ===================================================
const _getFinalEditorCurrentJobs = state => state.lists[constants.FINAL_EDITOR_CURRENT_JOBS];
export const getFinalEditorCurrentJobs = createSelector(
  [_getFinalEditorCurrentJobs, getEntities], _getDenormalizedJobs
)

// Second Final Editor Current Jobs ============================================
const _getSecondFinalEditorCurrentJobs = state => state.lists[constants.SECOND_FINAL_EDITOR_CURRENT_JOBS];
export const getSecondFinalEditorCurrentJobs = createSelector(
  [_getSecondFinalEditorCurrentJobs, getEntities], _getDenormalizedJobs
)

// DTP Current Jobs ============================================================
const _getDtpCurrentJobs = state => state.lists[constants.DTP_CURRENT_JOBS];
export const getDtpCurrentJobs = createSelector(
  [_getDtpCurrentJobs, getEntities], _getDenormalizedJobs
)

// Second DTP Current Jobs =====================================================
const _getSecondDtpCurrentJobs = state => state.lists[constants.SECOND_DTP_CURRENT_JOBS];
export const getSecondDtpCurrentJobs = createSelector(
  [_getSecondDtpCurrentJobs, getEntities], _getDenormalizedJobs
)

// Working List ================================================================
// const _getWorkingJobs = state => state.lists[constants.WORKING_LIST];
// export const getWorkingJobs = createSelector(
//   [_getWorkingJobs, getEntities], _getDenormalizedJobs
// )

// Draft List ==================================================================
const _getDraftJobs = state => state.lists[constants.JOB_DRAFT_LIST];
export const getDraftJobs = createSelector(
  [_getDraftJobs, getEntities], _getDenormalizedJobs
)

// RnQ List ================================================================
const getRnQJobTotalCount = state => state.lists[constants.RNQ_LIST].totalCount;
const getRnQJobPages = state => state.lists[constants.RNQ_LIST].pages;
export const getRnQJobCurrentPage = state => state.lists[constants.RNQ_LIST].currentPage;
export const getRnQJobPageSize = createSelector(
  [getRnQJobTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageRnQJobIds = createSelector(
  [getRnQJobCurrentPage, getRnQJobPages], (currentPage, pages) => pages[currentPage] || []
)
export const getRnQJobs = createSelector(
  [getCurrentPageRnQJobIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.jobSchema], entities)
)

// Delivery List ===============================================================
// const _getDeliveryJobs = state => state.lists[constants.DELIVERY_LIST];
// export const getDeliveryJobs = createSelector(
//   [_getDeliveryJobs, getEntities], _getDenormalizedJobs
// )

const getDeliveryJobTotalCount = state => state.lists[constants.DELIVERY_LIST].totalCount;
const getDeliveryJobPages = state => state.lists[constants.DELIVERY_LIST].pages;
export const getDeliveryJobCurrentPage = state => state.lists[constants.DELIVERY_LIST].currentPage;
export const getDeliveryJobPageSize = createSelector(
  [getDeliveryJobTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageDeliveryJobIds = createSelector(
  [getDeliveryJobCurrentPage, getDeliveryJobPages], (currentPage, pages) => pages[currentPage] || []
)
export const getDeliveryJobs = createSelector(
  [getCurrentPageDeliveryJobIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.jobSchema], entities)
)

// ToTra List ==================================================================
const totraStatus = EMPLOYEE_STATUS_MAP['totra'];
const _getToTraJobs = state => state.lists[constants.TOTRA_LIST];
export const getToTraJobs = createSelector(
  [_getToTraJobs, getEntities],
  (jobIds, entities) => {
    const totraJobs = denormalize(jobIds, [schemas.jobSchema], entities);
    return {
      validatingJobs: totraJobs.filter(job => job.status === totraStatus.validate),
      sentToJobs: totraJobs.filter(job => job.status === totraStatus.sent),
    }
  }
)

// Job History =================================================================
const _getJobHistory = state => state.lists[constants.EMPLOYEE_JOB_HISTORY];
export const getJobHistory = createSelector(
  [_getJobHistory, getEntities], _getDenormalizedJobs
)

const getJobHistoryTotalCount = state => state.lists[constants.EMPLOYEE_JOB_HISTORY].totalCount;
const getJobHistoryPages = state => state.lists[constants.EMPLOYEE_JOB_HISTORY].pages;
export const getJobHistoryCurrentPage = state => state.lists[constants.EMPLOYEE_JOB_HISTORY].currentPage;
export const getJobHistoryPageSize = createSelector(
  [getJobHistoryTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageHistoryJobIds = createSelector(
  [getJobHistoryCurrentPage, getJobHistoryPages], (currentPage, pages) => pages[currentPage] || []
)
export const getHistoryJobs = createSelector(
  [getCurrentPageHistoryJobIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.jobSchema], entities)
)

// Payment Search =====================================================
const _getPaymentSearch = state => state.lists[constants.PAYMENT_SEARCH];
export const getPaymentSearch = createSelector(
  [_getPaymentSearch, getEntities],
  (assignmentIds, entities) => denormalize(assignmentIds, [schemas.paymentSchema], entities)
)

// Template List ===============================================================
const _getTemplates = state => state.lists[constants.TEMPLATE_LIST];
export const getTemplates = createSelector(
  [_getTemplates, getEntities],
  (templateIds, entities) => denormalize(templateIds, [schemas.templateSchema], entities)
)

// User List ===================================================================
// const _getUsers = state => state.lists[constants.USER_LIST];
// export const getUsers = createSelector(
//   [_getUsers, getEntities],
//   (userIds, entities) => denormalize(userIds, [schemas.userSchema], entities)
// )

const getUserListTotalCount = state => state.lists[constants.USER_LIST].totalCount;
const getUserListPages = state => state.lists[constants.USER_LIST].pages;
export const getUserListCurrentPage = state => state.lists[constants.USER_LIST].currentPage;
export const getUserListPageSize = createSelector(
  [getUserListTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageUserIds = createSelector(
  [getUserListCurrentPage, getUserListPages],
  (currentPage, pages) => pages[currentPage] || []
)
export const getUsers = createSelector(
  [getCurrentPageUserIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.userSchema], entities)
)

// Client List =================================================================
const getClientListTotalCount = state => state.lists[constants.CLIENT_LIST].totalCount;
const getClientListPages = state => state.lists[constants.CLIENT_LIST].pages;
export const getClientListCurrentPage = state => state.lists[constants.CLIENT_LIST].currentPage;
export const getClientListPageSize = createSelector(
  [getClientListTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageClientIds = createSelector(
  [getClientListCurrentPage, getClientListPages],
  (currentPage, pages) => pages[currentPage] || []
)
export const getClients = createSelector(
  [getCurrentPageClientIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.clientSchema], entities)
)

// Client NG User ==============================================================
const getClientNgUsers = state => state.lists[constants.CLIENT_NG_USER];
const _getClientNgUsersById = createSelector(
  [coreSelectors.getId, getClientNgUsers],
  (id, ngUsers) => ngUsers[id] || []
)
export const getClientNgUsersById = createSelector(
  [_getClientNgUsersById, getEntities],
  (ngUserIds, entities) => {
    return denormalize(ngUserIds, [schemas.ngUserSchema], entities)
  }
);

// Client Preferable User ======================================================
const getClientPreferableUsers = state => state.lists[constants.CLIENT_PREFERABLE_USER];
const _getClientPreferableUsersById = createSelector(
  [coreSelectors.getId, getClientPreferableUsers],
  (id, preferableUsers) => preferableUsers[id] || []
)
export const getClientPreferableUsersById = createSelector(
  [_getClientPreferableUsersById, getEntities],
  (preferableUserIds, entities) => {
    return denormalize(preferableUserIds, [schemas.preferableUserSchema], entities)
  }
)

// Client Common Reference =====================================================
const getClientCommonReferences = state => state.lists[constants.CLIENT_COMMON_REFERENCE];
const _getClientCommonReferencesById = createSelector(
  [coreSelectors.getId, getClientCommonReferences],
  (id, references) => references[id] || []
)
export const getClientCommonReferencesById = createSelector(
  [_getClientCommonReferencesById, getEntities],
  (referenceIds, entities) => {
    return denormalize(referenceIds, [schemas.clientReferenceSchema], entities)
  }
)

// Job Sourcefiles =============================================================
const getJobSourcefiles = state => state.lists[constants.JOB_SOURCEFILE];
const _getJobSourcefilesById = createSelector(
  [coreSelectors.getId, getJobSourcefiles],
  (id, sourcefiles) => sourcefiles[id] || []
)
export const getJobSourcefilesById = createSelector(
  [_getJobSourcefilesById, getEntities],
  (sourcefileIds, entities) => {
    return denormalize(sourcefileIds, [schemas.sourcefileSchema], entities)
  }
)

// Job References ==============================================================
const getJobReferences = state => state.lists[constants.JOB_REFERENCE];
const _getJobReferencesById = createSelector(
  [coreSelectors.getId, getJobReferences],
  (id, references) => references[id] || []
)
export const getJobReferencesById = createSelector(
  [_getJobReferencesById, getEntities],
  (referenceIds, entities) => {
    return denormalize(referenceIds, [schemas.referenceSchema], entities)
  }
)

// Job Common References =======================================================
const getJobCommonReferences = state => state.lists[constants.JOB_COMMON_REFERENCE];
const _getJobCommonReferencesByGroup = createSelector(
  [coreSelectors.getId, getJobCommonReferences],
  (group, references) => references[group] || []
)
export const getJobCommonReferencesByGroup = createSelector(
  [_getJobCommonReferencesByGroup, getEntities],
  (referenceIds, entities) => {
    return denormalize(referenceIds, [schemas.jobCommonReferenceSchema], entities)
  }
)

// Job Client References =======================================================
const getJobClientReferences = state => state.lists[constants.JOB_CLIENT_REFERENCE];
const _getJobClientReferencesById = createSelector(
  [coreSelectors.getId, getJobClientReferences],
  (id, references) => references[id] || []
)
export const getJobClientReferencesById = createSelector(
  [_getJobClientReferencesById, getEntities],
  (referenceIds, entities) => {
    return denormalize(referenceIds, [schemas.clientReferenceSchema], entities)
  }
)

// Assignment Projects =========================================================
const getAssignmentProjects = state => state.lists[constants.ASSIGNMENT_PROJECT];
const _getAssignmentProjectsById = createSelector(
  [coreSelectors.getId, getAssignmentProjects],
  (id, projects) => projects[id] || []
)
export const getAssignmentProjectsById = createSelector(
  [_getAssignmentProjectsById, getEntities],
  (projectIds, entities) => {
    return denormalize(projectIds, [schemas.projectSchema], entities)
  }
)


// Job Messages ================================================================
const getJobMessages = state => state.lists[constants.JOB_MESSAGE_LIST];
const _getJobMessagesById = createSelector(
  [coreSelectors.getId, getJobMessages],
  (id, jobMessages) => jobMessages[id] || []
)
export const getJobMessagesById = createSelector(
  [_getJobMessagesById, getEntities],
  (messageIds, entities) => {
    const messages = denormalize(messageIds, [schemas.messageSchema], entities)
    return orderBy(messages, ['created'], ['desc']);
  }
)

// Job Invoices ================================================================
const getJobInvoices = state => state.lists[constants.JOB_INVOICE_LIST];
const _getJobInvoicesById = createSelector(
  [coreSelectors.getId, getJobInvoices],
  (id, jobInvoices) => jobInvoices[id] || []
)
export const getJobInvoicesById = createSelector(
  [_getJobInvoicesById, getEntities],
  (invoiceIds, entities) => {
    return denormalize(invoiceIds, [schemas.invoiceSchema], entities)
  }
)

// Invoice List ================================================================
const _getInvoices = state => state.lists[constants.INVOICE_LIST];
const getCurrentMonthInvoices = createSelector(
  [getInvoiceListCurrentMonth, _getInvoices],
  (currentMonth, invoices) => invoices[currentMonth]
)
export const getInvoices = createSelector(
  [getCurrentMonthInvoices, getEntities],
  (currentMonthInvoices, entities) => {
    const invoiceIds = currentMonthInvoices ? currentMonthInvoices : [];
    const invoices = denormalize(invoiceIds, [schemas.invoiceSchema], entities);
    const orderedInvoices = orderBy(invoices, ['group'])
    return {
      lsInvoices: orderedInvoices.filter(invoice => invoice.jobType === 'LS'),
      mdInvoices: orderedInvoices.filter(invoice => invoice.jobType === 'MD'),
      irInvoices: orderedInvoices.filter(invoice => invoice.jobType === 'IR'),
      trInvoices: orderedInvoices.filter(invoice => invoice.jobType === 'TR'),
      ttInvoices: orderedInvoices.filter(invoice => invoice.jobType === 'TT'),
      ntInvoices: orderedInvoices.filter(invoice => invoice.jobType === 'NT'),
    }
  }
)

const _getSalesReport = state => state.lists[constants.SALES_REPORT];
export const getSalesReport = createSelector(
  [getSalesReportCurrentMonth, _getSalesReport],
  (currentMonth, reports) => currentMonth && reports[currentMonth]
    ? reports[currentMonth]
    : {
        quotationResults: [],
        quotationTotal: { title: 'Total' },
        countResults: [],
        countTotal: { title: 'Total' },
        target: { title: 'Target' },
        progress: { title: 'Progress' }
      }
)


const _getSalesTargets = state => state.lists[constants.SALES_TARGET];
export const getCurrentYearSalesTargets = createSelector(
  [getSalesTargetCurrentYear, _getSalesTargets, getEntities],
  (currentYear, targets, entities) => {
    const targetIds = targets[currentYear] || [];
    return denormalize(targetIds, [schemas.salesTargetSchema], entities)
  }
)

export const getSourcefilesByForm = createSelector(
  [coreSelectors.getFormValueSelector, getEntities],
  (fileIds, entities) => denormalize(fileIds, [schemas.sourcefileSchema], entities)
)
export const getReferencesByForm = createSelector(
  [coreSelectors.getFormValueSelector, getEntities],
  (fileIds, entities) => denormalize(fileIds, [schemas.referenceSchema], entities)
)


// WordList ====================================================================

const getWordListTotalCount = state => state.lists[constants.WORDLIST].totalCount;
const getWordListPages = state => state.lists[constants.WORDLIST].pages;


export const getWordListCurrentPage = state => state.lists[constants.WORDLIST].currentPage;
export const getWordListPageSize = createSelector(
  [getWordListTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageWordListIds = createSelector(
  [getWordListCurrentPage, getWordListPages],
  (currentPage, pages) => pages[currentPage] || []
)
export const getWordLists = createSelector(
  [getCurrentPageWordListIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.wordlistSchema], entities)
)

export const getCurrentWordListDetail = createSelector(
  [getCurrentTabParams, getWordListEntities],
  (params, wordLists) => {
    return wordLists[params.id];
  }
)


// Glossary ====================================================================
const getGlossaryListTotalCount = state => state.lists[constants.GLOSSARY_LIST].totalCount;
const getGlossaryListPages = state => state.lists[constants.GLOSSARY_LIST].pages;
export const getGlossaryListCurrentPage = state => state.lists[constants.GLOSSARY_LIST].currentPage;
export const getGlossaryListPageSize = createSelector(
  [getGlossaryListTotalCount],
  (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageGlossaryListIds = createSelector(
  [getGlossaryListCurrentPage, getGlossaryListPages],
  (currentPage, pages) => pages[currentPage] || []
)
export const getGlossaries = createSelector(
  [getCurrentPageGlossaryListIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.glossarySchema], entities)
)

export const getCurrentGlossaryDetail = createSelector(
  [getCurrentTabParams, getGlossaryEntities],
  (params, glossaries) => {
    return glossaries[params.id];
  }
)

// Terms =======================================================================
export const getTermSearchHits = state => state.lists[constants.TERM_SEARCH].hits;
export const getTermTotalCount = state => state.lists[constants.TERM_SEARCH].totalCount;
const getTermsPages = state => state.lists[constants.TERM_SEARCH].pages;
export const getTermsCurrentPage = state => state.lists[constants.TERM_SEARCH].currentPage;
export const getTermsPageSize = createSelector(
  [getTermTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
const getCurrentPageTerms = createSelector(
  [getTermsCurrentPage, getTermsPages],
  (currentPage, pages) => {
    return pages[currentPage] && Object.keys(pages[currentPage]).length !== 0
      ? pages[currentPage]
      : { wordlistItems: [], glossaryItems: [] }
  }
)

export const getTerms = createSelector(
  [getCurrentPageTerms, getEntities],
  (terms, entities) => {
    return {
      terms: [
        ...denormalize(terms.wordlistItems, [schemas.wordlistItemSchema], entities),
        ...denormalize(terms.glossaryItems, [schemas.glossaryItemSchema], entities)
      ]
    }
  }
)

// Memory Search List ==========================================================
const getMemorySearchListTotalCount = state => state.lists[constants.MEMORY_SEARCH_LIST].totalCount;
const getMemorySearchListPages = state => state.lists[constants.MEMORY_SEARCH_LIST].pages;
export const getMemorySearchListCurrentPage = state => state.lists[constants.MEMORY_SEARCH_LIST].currentPage;
export const getMemorySearchListPageSize = createSelector(
  [getMemorySearchListTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageMemorySearchIds = createSelector(
  [getMemorySearchListCurrentPage, getMemorySearchListPages],
  (currentPage, pages) => pages[currentPage] || []
)
export const getMemorySearches = createSelector(
  [getCurrentPageMemorySearchIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.memorySearchSchema], entities)
)

// MHT Detail =================================================================

export const getCurrentTranslationMemoryDetail = createSelector(
  [getCurrentTabParams, getMemorySearchEntities],
  (params, memories) => {
    return memories[params.id];
    // return denormalize(params.id, schemas.memorySchema, entities)
  }
)

// MHT List =================================================================
export const getMemorySearchHits = state => state.lists[constants.MEMORY_SEARCH].hits;
const getMemorySearchTotalCount = state => state.lists[constants.MEMORY_SEARCH].totalCount;
const getMemorySearchPages = state => state.lists[constants.MEMORY_SEARCH].pages;
export const getMemorySearchCurrentPage = state => state.lists[constants.MEMORY_SEARCH].currentPage;
export const getMemorySearchPageSize = createSelector(
  [getMemorySearchTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageMemoryIds = createSelector(
  [getMemorySearchCurrentPage, getMemorySearchPages],
  (currentPage, pages) => pages[currentPage] || []
)
export const getMemories = createSelector(
  [getCurrentPageMemoryIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.memorySchema], entities)
)

export const getSelectedTranslationMemoryItem = createSelector(
  [getCurrentTabParams, getMemorySearchEntities],
  (params, memories) => {
    return memories[params.id];
  }
)
export const getSelectedTranslationMemoryItemNormalized = createSelector(
  [getCurrentTabParams, getEntities],
  (params, entities) => {
    return denormalize(params.id, schemas.memorySearchSchema, entities)
  }
)


const _getAnalysisReport = state => state.lists[constants.ANALYSIS_REPORT];
export const getAnalysisReport = createSelector(
  [getSalesReportCurrentMonth, _getAnalysisReport],
  (currentMonth, reports) => currentMonth && reports[currentMonth]
    ? reports[currentMonth]
    : {
        results: [],
        avgMonthly: { title: 'Avg.' },
      }
)

// Customer List =================================================================
const getCustomerListTotalCount = state => state.lists[constants.CUSTOMER_LIST].totalCount;
const getCustomerListPages = state => state.lists[constants.CUSTOMER_LIST].pages;
export const getCustomerListCurrentPage = state => state.lists[constants.CUSTOMER_LIST].currentPage;
export const getCustomerListPageSize = createSelector(
  [getCustomerListTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageCustomerIds = createSelector(
  [getCustomerListCurrentPage, getCustomerListPages],
  (currentPage, pages) => pages[currentPage] || []
)
export const getCustomers = createSelector(
  [getCurrentPageCustomerIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.customerSchema], entities)
)

// Quote List =================================================================
const getQuoteListTotalCount = state => state.lists[constants.QUOTE_LIST].totalCount;
const getQuoteListPages = state => state.lists[constants.QUOTE_LIST].pages;
export const getQuoteListCurrentPage = state => state.lists[constants.QUOTE_LIST].currentPage;
export const getQuoteListPageSize = createSelector(
  [getQuoteListTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageQuoteIds = createSelector(
  [getQuoteListCurrentPage, getQuoteListPages],
  (currentPage, pages) => pages[currentPage] || []
)
export const getQuotes = createSelector(
  [getCurrentPageQuoteIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.quoteSchema], entities)
)

// MHT Job List
const getMHTJobListTotalCount = state => state.lists[constants.MHT_JOB_LIST].totalCount;
const getMHTJobListPages = state => state.lists[constants.MHT_JOB_LIST].pages;
export const getMHTJobListCurrentPage = state => state.lists[constants.MHT_JOB_LIST].currentPage;
export const getMHTJobListPageSize = createSelector(
  [getMHTJobListTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageMHTJobIds = createSelector(
  [getMHTJobListCurrentPage, getMHTJobListPages],
  (currentPage, pages) => pages[currentPage] || []
)
export const getMHTJobs = createSelector(
  [getCurrentPageMHTJobIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.jobSchema], entities)
)

// Job Messages ================================================================
const getMHTJobMessages = state => state.lists[constants.MHT_JOB_MESSAGE];
const _getMHTJobMessagesById = createSelector(
  [coreSelectors.getId, getMHTJobMessages],
  (id, mhtJobMessages) => mhtJobMessages[id] || []
)
export const getMHTJobMessagesById = createSelector(
  [_getMHTJobMessagesById, getEntities],
  (messageIds, entities) => {
    const messages = denormalize(messageIds, [schemas.mhtJobMessageSchema], entities)
    return orderBy(messages, ['created'], ['asc']);
  }
)

// Quote Sourcefiles =============================================================
const getQuoteSourcefiles = state => state.lists[constants.QUOTE_SOURCEFILE];
const _getQuoteSourcefilesById = createSelector(
  [coreSelectors.getId, getQuoteSourcefiles],
  (id, sourcefiles) => sourcefiles[id] || []
)
export const getQuoteSourcefilesById = createSelector(
  [_getQuoteSourcefilesById, getEntities],
  (sourcefileIds, entities) => {
    return denormalize(sourcefileIds, [schemas.quoteSourcefileSchema], entities)
  }
)

// Unsupported Order List ============================================================= 
const getUnsupportedOrderListTotalCount = state => state.lists[constants.UNSUPPORTED_ORDER_LIST].totalCount;
const getUnsupportedOrderListPages = state => state.lists[constants.UNSUPPORTED_ORDER_LIST].pages;
export const getUnsupportedOrderListCurrentPage = state => state.lists[constants.UNSUPPORTED_ORDER_LIST].currentPage;
export const getUnsupportedOrderListPageSize = createSelector(
  [getUnsupportedOrderListTotalCount], (totalCount) => Math.ceil(totalCount / PER_PAGE_ITEM)
)
export const getCurrentPageUnsupportedOrderIds = createSelector(
  [getUnsupportedOrderListCurrentPage, getUnsupportedOrderListPages],
  (currentPage, pages) => pages[currentPage] || []
)
export const getUnsupportedOrders = createSelector(
  [getCurrentPageUnsupportedOrderIds, getEntities],
  (ids, entities) => denormalize(ids, [schemas.unsupportedOrderSchema], entities)
)