import handleClipboard from "components/mht/MHTEditor/ckEditorConfig/plugins/handleClipboard";
import disableSoftEnter from "components/mht/MHTEditor/ckEditorConfig/plugins/disableSoftEnter";

function balloonToolbarSettings(editor) {
  const balloonToolbar = editor.plugins.get( 'BalloonToolbar' )
  // balloonToolbar.toolbarView.element.style.minWidth = '396px';
  balloonToolbar.toolbarView.element.style.minWidth = '474px';
}

const colors = [
  {
    color: '#FFFFFF',
    label: 'White',
    hasBorder: true
  },
  {
    color: '#D3D3D3',
    label: 'Light Gray'
  },
  {
    color: '#808080',
    label: 'Gray'
  },
  {
    color: '#696969',
    label: 'Dim Gray'
  },
  {
    color: '#000000',
    label: 'Black'
  },
  {
    color: '#FFFF00',
    label: 'Yellow'
  },
  {
    color: '#808000',
    label: 'Dark Yellow'
  },
  {
    value: '#FF9900',
    label: 'Orange'
  },
  {
    color: '#FF0000',
    label: 'Red'
  },
  {
    color: '#8B0000',
    label: 'Dark Red'
  },
  {
    color: '#FF00FF',
    label: 'Mageta (ピンク)'
  },
  {
    color: '#8B008B',
    label: 'Dark Mageta'
  },
  {
    color: '#00B0F0',
    label: 'Sky Blue (水色)'
  },
  {
    color: '#0000FF',
    label: 'Blue'
  },
  {
    color: '#00008B',
    label: 'Dark Blue'
  },
  {
    color: '#00FFFF',
    label: 'Cyan'
  },
  {
    color: '#008B8B',
    label: 'Dark Cyan'
  },
  {
    color: '#92D050',
    label: 'Yellow Green (黄緑)'
  },
  {
    color: '#008000',
    label: 'Green'
  },
  {
    color: '#006400',
    label: 'Dark Green'
  }

];

/*
function generateFontSizes() {
  let sizes = [];
  while (sizes.length < 100) {
    const size = sizes.length + 1;
    sizes.push({
      model: size,
      title: size,
      view: {
        name: 'span',
        styles: {
          'font-size': `${size}pt`
        }
      }
    });
  }

  return sizes;

}
*/

export const ckEditorConfigTarget = {
  // fontSize: {
  //   options: generateFontSizes()
  // },
  fontBackgroundColor: {
    colors: colors
  },
  fontColor: {
    colors: colors
  },
  extraPlugins: [
    disableSoftEnter,
    handleClipboard,
    balloonToolbarSettings
  ]
};
