import React from 'react';
import {Checkbox, Radio} from 'react-bootstrap';

import {TaModal, TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import MHTApi from "services/MHTApi";

import {TaButton} from "components/taUi/taButton/taButton";
import {TaFormGroup} from "components/taUi/taFormGroup/taFormGroup";
import {TaFormField} from "components/taUi/taFormField/taFormField";
import * as PropTypes from "prop-types";
import {TaFormMessages} from "components/taUi/taFormMessage/taFormMessage";

class TaMhtEditorMachineTranslateModalContent extends React.Component {

  static propTypes = {
    mhtId: PropTypes.string.isRequired,
    selectedSegment: PropTypes.any,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onError: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      formMessages: [],
      formData: {
        include_tentative: true,
        use_ai_translate: "no"
      },
    };
  }

  componentDidMount() {
    this.formClear();
  }

  onCancel = () => {
    this.formClear();
    if (typeof this.props.onCancel === 'function') {
      this.props.onCancel();
    }
  };

  onConfirm = (response) => {
    this.formClear();
    if (typeof this.props.onConfirm === 'function') {
      this.props.onConfirm(response.data);
    }
  };

  onError = (error) => {

    let message = 'Error';

    // if (
    //   error.response &&
    //   error.response.data &&
    //   error.response.data.file
    // ) {
    //   message += ': Please format the content of the Excel file according to specifications. Use the file generated by "Export Bilingual File" as a reference';
    // } else
    if (error.message) {
      message += ': ' + error.message;
    }

    this.setState({
      errors: [{type: 'error', message: message}],
      isSaving: false
    });

    if (typeof this.props.onError === 'function') {
      this.props.onError();
    }

  };

  formClear = () => {
    this.setState({
      errors: [],
      isSaving: false,
      isDirty: false,
      formData: {
        include_tentative: true,
        use_ai_translate: "no"
      },
    });
  };

  onSubmit = () => {

    this.setState({
      isSaving: true
    }, () => {
      MHTApi
        .machineTranslateSegments(
            this.props.mhtId,
            (this.props.selectedSegment && this.props.selectedSegment.items) ? Object.keys(this.props.selectedSegment.items) : [],
            this.state.formData
        )
        .then(this.onConfirm)
        .catch(this.onError);
    });
  };

  onOptionChange = (key, value) => {
    this.setState({
      isDirty: true,
      formData: {
        ...this.state.formData,
        [key]: value
      }
    });
  };

  render() {

    const {
      formData,
      isSaving
    } = this.state;

    const {
      onCancel
    } = this.props;

    return (
      <React.Fragment>
        <TaModalHeader
          label={'Machine Translate Segments'}
          onCancel={onCancel}
        />
        <TaModalContent>
          <TaFormGroup>


            {/*<TaFormField _label="include tentative">*/}
              <Checkbox
                  name="include_tentative"
                  value={formData.include_tentative}
                  checked={formData.include_tentative}
                  onChange={e => this.onOptionChange('include_tentative', e.target.checked)}
                  >
                include tentative
              </Checkbox>
              <TaFormField label="AI Translate">
              {/* <Checkbox
                  name="use_google_translate"
                  value={formData.use_google_translate}
                  checked={formData.use_google_translate}
                  onChange={e => this.onOptionChange('use_google_translate', e.target.checked)}
              >
                use google translate
              </Checkbox> */}
              <Radio
                checked={formData.use_ai_translate === "google"}
                onChange={e => this.onOptionChange('use_ai_translate', "google")}>Google Translate
              </Radio>
              <Radio
                checked={formData.use_ai_translate === "deepl"}
                value={formData.use_ai_translate}
                onChange={e => this.onOptionChange('use_ai_translate', "deepl")}>DeepL
              </Radio>
              <Radio
                checked={formData.use_ai_translate === "no"}
                value={formData.use_ai_translate}
                onChange={e => this.onOptionChange('use_ai_translate', "no")}>No
              </Radio>
            </TaFormField>
          </TaFormGroup>
          <TaFormMessages messages={this.state.errors}/>
        </TaModalContent>
        <TaModalFooter>
          <TaButton
            type="reset"
            icon={'clear'}
            label={'Cancel'}
            onClick={() => onCancel()}
          />
          <TaButton
            type="submit"
            icon={'check'}
            label={'Submit'}
            onClick={() => this.onSubmit()}
            isLoading={isSaving}
          />
        </TaModalFooter>
      </React.Fragment>
    );
  }
}


class TaMhtEditorMachineTranslateModal extends React.Component {

  static propTypes = {
    mhtId: PropTypes.string.isRequired,
    selectedSegment: PropTypes.any,
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onError: PropTypes.func
  };

  render() {

    const {
      mhtId,
      selectedSegment,
      isOpen,
      onCancel,
      onConfirm,
      onError
    } = this.props;

    return (
      <TaModal
        isOpen={isOpen}
        onCancel={onCancel}
      >
        {(!!isOpen) && (
          <TaMhtEditorMachineTranslateModalContent
            mhtId={mhtId}
            selectedSegment={selectedSegment}
            onCancel={onCancel}
            onConfirm={onConfirm}
            onError={onError}
          />
        )}
      </TaModal>
    );
  }

}

export default TaMhtEditorMachineTranslateModal;
