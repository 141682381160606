import React from 'react';
import { connect } from 'react-redux';
import { Tab } from 'react-bootstrap';
import { Layout, Content } from 'reactjs-admin-lte';
import * as constants from 'misc/constants';
import * as authSelectors from 'selectors/auth';
import Header from 'components/core/Header';
import GlobalTabs from 'components/core/GlobalTabs';
import { ManagerSidebar } from 'components/core/Sidebar';
import { SingleFileUploadModal } from 'components/documents/FileUploadModal';
import FileUpdateModal from 'components/documents/FileUpdateModal';
import ClientUserPreferenceModal from 'components/clients/ClientUserPreference/ClientUserPreferenceModal';
import JobProcessModal from 'components/jobs/managers/JobProcessModal';
import JobInvoiceModal from 'components/jobs/managers/JobInvoiceModal';
import TemplateCreateModal from 'components/templates/TemplateCreateModal';

// Calendar
import Calendar from 'components/calendars/Calendar';

// Client
import ClientCreate from 'components/clients/ClientCreate';
import ClientList from 'components/clients/ClientList';
import ClientDetail from 'components/clients/ClientDetail';

// User
import UserCreate from 'components/users/UserCreate';
import UserList from 'components/users/UserList';
import UserDetail from 'components/users/UserDetail';

// Job
import JobCreate from 'components/jobs/managers/JobCreate';
import JobDuplicate from 'components/jobs/managers/JobDuplicate';
import JobCreateByTemplate from 'components/jobs/managers/JobCreateByTemplate';
import JobSplit from 'components/jobs/managers/JobSplit';
import JobIssue from 'components/jobs/managers/JobIssue';
import JobSplitIssue from 'components/jobs/managers/JobSplitIssue';
import JobSearch from 'components/jobs/managers/JobSearch';
import JobEdit from 'components/jobs/managers/JobEdit';
import JobDetail from 'components/jobs/managers/JobDetail';
import JobSchedule from 'components/jobs/managers/JobSchedule';
// import JobWorkingList from 'components/jobs/managers/JobWorkingList';
import JobDraftList from 'components/jobs/managers/JobDraftList';
import JobDraftEdit from 'components/jobs/managers/JobDraftEdit';
import JobPendingList from 'components/jobs/managers/JobPendingList';
import JobPreparingList from 'components/jobs/managers/JobPreparingList';
import JobToTraList from 'components/jobs/managers/JobToTraList';
import JobRnQList from 'components/jobs/managers/JobRnQList';
import JobDeliveryList from 'components/jobs/managers/JobDeliveryList';
import JobDeliveryDetail from 'components/jobs/managers/JobDeliveryDetail';
import EmployeeAssignmentProjectUploadModal from 'components/jobs/employees/EmployeeAssignmentProjectUploadModal';

import MHTJobList from 'components/jobs/managers/MHTJobList';
import MHTJobDetail from 'components/jobs/managers/MHTJobDetail';
import MHTJobMessageModal from 'components/jobs/managers/MHTJobMessage/MHTJobMessageModal';

// Template
import TemplateList from 'components/templates/TemplateList';
import TemplateEdit from 'components/templates/TemplateEdit';

import InvoiceList from 'components/invoices/InvoiceList';
import PaymentSearch from 'components/jobs/managers/PaymentSearch';

// Accounting
import SalesReport from 'components/accounting/SalesReport';
import SalesTarget from 'components/accounting/SalesTarget';

// MHT
import TermSearch from 'components/mht/TermSearch';

import WordList from 'components/mht/WordList';
import WordListUploadModal from 'components/mht/WordList/WordListUploadModal';
import WordListDetail from 'components/mht/WordListDetail';
import WordListSettingsModal from "components/mht/WordListDetail/WordListSettingsModal";
import WordListImportModal from "components/mht/WordListDetail/WordListImportModal";

import GlossaryList from 'components/mht/GlossaryList';
import GlossaryUploadModal from 'components/mht/GlossaryList/GlossaryUploadModal';
import GlossaryDetail from 'components/mht/GlossaryDetail';
import GlossarySettingsModal from 'components/mht/GlossaryDetail/GlossarySettingsModal';
import GlossaryImportModal from 'components/mht/GlossaryDetail/GlossaryImportModal';

import TranslationMemorySearch from 'components/mht/TranslationMemorySearch'
import TranslationMemorySearchSaveModal from 'components/mht/TranslationMemorySearch/TranslationMemorySearchSaveModal';
import TranslationMemoryList from 'components/mht/TranslationMemoryList'
import TranslationMemoryDetail from 'components/mht/TranslationMemoryDetail'
import MHTFileUploadModal from 'components/mht/MHTFileUploadModal';
import MHTSetupProgressModal from 'components/mht/MHTSetupProgressModal';
import MHTSetupErrorModal from 'components/mht/MHTSetupErrorModal';
import MHTEditor from 'components/mht/MHTEditor';
import TranslationMemoryUploadModal from 'components/mht/TranslationMemoryList/TranslationMemoryUploadModal';
import Profile from 'components/settings/Profile';
import SiteHOC from './SiteHOC';

import CustomerCreate from 'components/customers/CustomerCreate';
import CustomerList from 'components/customers/CustomerList';
import CustomerDetail from 'components/customers/CustomerDetail';

import QuoteList from 'components/quotes/QuoteList';
import QuoteDetail from 'components/quotes/QuoteDetail';
import MHTInvoiceDownloadModal from 'components/jobs/managers/MHTJobDetail/MHTInvoiceDownloadModal';
import UnsupportedOrderList from 'components/quotes/UnsupportedOrderList';
import UnsupportedOrderDetail from 'components/quotes/UnsupportedOrderDetail';

const componentMap = {
  // Calendar
  [constants.CALENDAR]: Calendar,
  // Client
  [constants.CLIENT_CREATE]: ClientCreate,
  [constants.CLIENT_LIST]: ClientList,
  [constants.CLIENT_DETAIL]: ClientDetail,
  // User
  [constants.USER_CREATE]: UserCreate,
  [constants.USER_LIST]: UserList,
  [constants.USER_DETAIL]: UserDetail,
  // Jobs
  [constants.JOB_CREATE]: JobCreate,
  [constants.JOB_DUPLICATE]: JobDuplicate,
  [constants.JOB_SPLIT]: JobSplit,
  [constants.JOB_CREATE_BY_TEMPLATE]: JobCreateByTemplate,
  [constants.JOB_ISSUE]: JobIssue,
  [constants.JOB_SPLIT_ISSUE]: JobSplitIssue,
  [constants.JOB_EDIT]: JobEdit,
  [constants.JOB_SCHEDULE]: JobSchedule,
  [constants.JOB_SEARCH]: JobSearch,
  [constants.JOB_DETAIL]: JobDetail,
  [constants.JOB_DRAFT_LIST]: JobDraftList,
  [constants.JOB_DRAFT_EDIT]: JobDraftEdit,
  // [constants.WORKING_LIST]: JobWorkingList,
  [constants.DELIVERY_LIST]: JobDeliveryList,
  [constants.DELIVERY_DETAIL]: JobDeliveryDetail,
  [constants.PENDING_LIST]: JobPendingList,
  [constants.PREPARING_LIST]: JobPreparingList,
  [constants.TOTRA_LIST]: JobToTraList,
  [constants.RNQ_LIST]: JobRnQList,

  // Templates
  [constants.TEMPLATE_LIST]: TemplateList,
  [constants.TEMPLATE_EDIT]: TemplateEdit,
  // Invoices
  [constants.INVOICE_LIST]: InvoiceList,
  // Payment Search
  [constants.PAYMENT_SEARCH]: PaymentSearch,
  // Report
  [constants.SALES_REPORT]: SalesReport,
  [constants.SALES_TARGET]: SalesTarget,
  // MHT
  [constants.TERM_SEARCH]: TermSearch,
  [constants.WORDLIST]: WordList,
  [constants.WORDLIST_DETAIL]: WordListDetail,
  [constants.GLOSSARY_LIST]: GlossaryList,
  [constants.GLOSSARY_DETAIL]: GlossaryDetail,
  [constants.TRANSLATION_MEMORY_SEARCH]: TranslationMemorySearch,
  [constants.TRANSLATION_MEMORY_LIST]: TranslationMemoryList,
  [constants.TRANSLATION_MEMORY_DETAIL]: TranslationMemoryDetail,
  [constants.MHT_EDITOR]: MHTEditor,
  // settings
  [constants.PROFILE]: Profile,

  // customers
  [constants.CUSTOMER_CREATE]: CustomerCreate,
  [constants.CUSTOMER_LIST]: CustomerList,
  [constants.CUSTOMER_DETAIL]: CustomerDetail,
  // quotes
  [constants.QUOTE_LIST]: QuoteList,
  [constants.QUOTE_DETAIL]: QuoteDetail,

  [constants.MHT_JOB_LIST]: MHTJobList,
  [constants.MHT_JOB_DETAIL]: MHTJobDetail,

  [constants.UNSUPPORTED_ORDER_LIST]: UnsupportedOrderList,
  [constants.UNSUPPORTED_ORDER_DETAIL]: UnsupportedOrderDetail
}

const Manager = (props) => {
  const {
    skin,
    me,
    logout,
    tabs,
    currentTab,
    renderTabContent,
    handleTabOpen,
    handleTabSelect,
    handleTabClose,
    handleOpenTabCloseConfirm
  } = props;

  return (
    <Layout skin={skin}>
      <Header
        me={me}
        logout={logout}
        handleTabOpen={handleTabOpen} />
      <ManagerSidebar
        {...props}
        handleTabOpen={handleTabOpen} />
      <Content>
        <Tab.Container
          id="global-tabset"
          activeKey={currentTab}
          onSelect={handleTabSelect}>
          <div>
            <GlobalTabs
              tabs={tabs}
              activeKey={currentTab}
              onSelect={handleTabSelect}
              handleTabClose={handleTabClose}
              handleOpenTabCloseConfirm={handleOpenTabCloseConfirm} />
              <Tab.Content animation={false}>
                {renderTabContent(componentMap)}
              </Tab.Content>
          </div>
        </Tab.Container>
      </Content>
      <WordListUploadModal />
      <WordListSettingsModal/>
      <WordListImportModal/>
      <GlossaryUploadModal />
      <GlossarySettingsModal />
      <GlossaryImportModal />
      <MHTSetupProgressModal />
      <MHTSetupErrorModal />
      <TranslationMemoryUploadModal />
      <TranslationMemorySearchSaveModal />
      <TemplateCreateModal />
      <JobProcessModal />
      <JobInvoiceModal />
      <ClientUserPreferenceModal />
      <SingleFileUploadModal />
      <FileUpdateModal />
      <MHTFileUploadModal />
      <EmployeeAssignmentProjectUploadModal />
      <MHTJobMessageModal />
      <MHTInvoiceDownloadModal />
    </Layout>
  );
}


const mapStateToProps = (state) => {
  return {
    isAE: authSelectors.isAE(state),
    isCoordinator: authSelectors.isCoordinator(state),
    isAccounting: authSelectors.isAccounting(state),
  }
}

export default connect(
  mapStateToProps,
)(SiteHOC(Manager));
