import * as types from 'actionTypes/clients';
import * as utils from 'misc/utils';

const createUserPreferenceListAction = (type) => ({
  requestFn: (clientId) => ({ type: type.REQUEST, payload: { clientId } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

const createUserPreferenceAddAction = (type) => ({
  requestFn: (clientId, values) => ({ type: type.REQUEST, payload: { clientId, values } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

const createUserPreferenceRemoveAction = (type) => ({
  requestFn: (preference) => ({ type: type.REQUEST, payload: { preference } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

// Create ======================================================================
const create = utils.createCreateAction(types.CREATE);
export const createRequest = create.requestFn;
export const createSuccess = create.successFn;
export const createFailure = create.failureFn;

// List ========================================================================
const list = utils.createSearchAction(types.LIST);
export const listRequest = list.requestFn;
export const listSuccess = list.successFn;
export const listFailure = list.failureFn;

// Retrieve ====================================================================
const retrieve = utils.createRetrieveAction(types.RETRIEVE);
export const retrieveRequest = retrieve.requestFn;
export const retrieveSuccess = retrieve.successFn;
export const retrieveFailure = retrieve.failureFn;

// Update ======================================================================
const update = utils.createUpdateAction(types.UPDATE);
export const updateRequest = update.requestFn;
export const updateSuccess = update.successFn;
export const updateFailure = update.failureFn;

const remove = utils.createRemoveAction(types.REMOVE);
export const removeRequest = remove.requestFn;
export const removeSuccess = remove.successFn;
export const removeFailure = remove.failureFn;

// Autocomplete ================================================================
export const autocompleteRequest = (q) => ({
  type: types.AUTOCOMPLETE.REQUEST,
  payload: { q }
});

export const autocompleteSuccess = () => ({
  type: types.AUTOCOMPLETE.SUCCESS
});

export const autocompleteFailure = (error) => ({
  type: types.AUTOCOMPLETE.FAILURE,
  payload: { error }
});

// Preferable User List ========================================================
const preferableUserList = createUserPreferenceListAction(types.PREFERABLE_USER_LIST);
export const preferableUserListRequest = preferableUserList.requestFn;
export const preferableUserListSuccess = preferableUserList.successFn;
export const preferableUserListFailure = preferableUserList.failureFn;

// Preferable User Add =========================================================
const preferableUserAdd = createUserPreferenceAddAction(types.PREFERABLE_USER_ADD);
export const preferableUserAddRequest = preferableUserAdd.requestFn;
export const preferableUserAddSuccess = preferableUserAdd.successFn;
export const preferableUserAddFailure = preferableUserAdd.failureFn;

// Preferable User Remove ======================================================
const preferableUserRemove = createUserPreferenceRemoveAction(types.PREFERABLE_USER_REMOVE);
export const preferableUserRemoveRequest = preferableUserRemove.requestFn;
export const preferableUserRemoveSuccess = preferableUserRemove.successFn;
export const preferableUserRemoveFailure = preferableUserRemove.failureFn;


// NG User List ================================================================
const ngUserList = createUserPreferenceListAction(types.NG_USER_LIST);
export const ngUserListRequest = ngUserList.requestFn;
export const ngUserListSuccess = ngUserList.successFn;
export const ngUserListFailure = ngUserList.failureFn;

// NG User Add =================================================================
const ngUserAdd = createUserPreferenceAddAction(types.NG_USER_ADD);
export const ngUserAddRequest = ngUserAdd.requestFn;
export const ngUserAddSuccess = ngUserAdd.successFn;
export const ngUserAddFailure = ngUserAdd.failureFn;

// NG User Remove ==============================================================
const ngUserRemove = createUserPreferenceRemoveAction(types.NG_USER_REMOVE);
export const ngUserRemoveRequest = ngUserRemove.requestFn;
export const ngUserRemoveSuccess = ngUserRemove.successFn;
export const ngUserRemoveFailure = ngUserRemove.failureFn;

// Common Reference List =======================================================
export const commonReferenceListRequest = (clientId) => ({
  type: types.COMMON_REFERENCE_LIST.REQUEST,
  payload: { clientId }
});
export const commonReferenceListSuccess = () => ({
  type: types.COMMON_REFERENCE_LIST.SUCCESS
})
export const commonReferenceListFailure = (error) => ({
  type: types.COMMON_REFERENCE_LIST.FAILURE,
  payload: { error }
})

// WordList ====================================================================
export const wordlistRequest = (clientId) => ({
  type: types.WORDLIST.REQUEST,
  payload: { clientId }
});
export const wordlistSuccess = () => ({
  type: types.WORDLIST.SUCCESS
})
export const wordlistFailure = (error) => ({
  type: types.WORDLIST.FAILURE,
  payload: { error }
})