import { createAsyncActionTypes } from 'misc/utils';

const PREFIX = 'clients'

export const LIST = createAsyncActionTypes(`${PREFIX}/LIST`);
export const CREATE = createAsyncActionTypes(`${PREFIX}/CREATE`);
export const RETRIEVE = createAsyncActionTypes(`${PREFIX}/RETRIEVE`);
export const UPDATE = createAsyncActionTypes(`${PREFIX}/UPDATE`);
export const REMOVE = createAsyncActionTypes(`${PREFIX}/REMOVE`);

export const AUTOCOMPLETE = createAsyncActionTypes(`${PREFIX}/AUTOCOMPLETE`);

export const PREFERABLE_USER_LIST = createAsyncActionTypes(`${PREFIX}/PREFERABLE_USER_LIST`);
export const PREFERABLE_USER_ADD = createAsyncActionTypes(`${PREFIX}/PREFERABLE_USER_ADD`);
export const PREFERABLE_USER_REMOVE = createAsyncActionTypes(`${PREFIX}/PREFERABLE_USER_REMOVE`);

export const NG_USER_LIST = createAsyncActionTypes(`${PREFIX}/NG_USER_LIST`);
export const NG_USER_ADD = createAsyncActionTypes(`${PREFIX}/NG_USER_ADD`);
export const NG_USER_REMOVE = createAsyncActionTypes(`${PREFIX}/NG_USER_REMOVE`);

export const COMMON_REFERENCE_LIST = createAsyncActionTypes(`${PREFIX}/COMMON_REFERENCE_LIST`);
export const WORDLIST = createAsyncActionTypes(`${PREFIX}/WORDLIST`);